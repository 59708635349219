@import url("./assets/fonts/stylesheet.css");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  font-size: 62.5%; /* 1rem = 10px */
}
@media (max-width: 992px) {
  html {
    font-size: 52.5%;
  }
}

body {
  height: 100%;
  overflow: auto;
  width: 100%;
  font-size: 1.3rem;
  font-family: Montserrat, sans-serif;
}

#root {
  height: 100%;
}
input {
  border-radius: 3px;
}
input:focus,
button:focus,
select:focus {
  outline: none;
}
button {
  cursor: pointer;
  border: none;
  background-color: white;
}

ul {
  list-style-type: none;
}

.react-toast-notifications__container {
  overflow: visible !important;
}

/* Custom scroll was applied to RecordingInstanceListItemUl */
.custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* datepicker css */

.CustomDateRangePicker > div {
  border-radius: 12px;
}

.CustomDateRangePicker {
  padding: 5px 0;
  top: 4px;
  position: absolute !important;
  z-index: 99 !important;
  right: 1%;
}

/* Sidebar css */
.CustomDateRangePicker > div > div > div:first-child {
  display: none;
}

.CustomDateRangePicker > div > div > hr {
  display: none;
}

/* header css */
.CustomDateRangePicker > div > div > div:last-child > div:first-child {
  display: none;
}

.CustomDateRangePicker > div > div > div:last-child > hr {
  display: none;
}

/* month year dropdown css */
.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div
  > div
  > div
  > div {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  padding-right: 0;
}

.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div
  > div
  > div
  > div:before {
  border-bottom: none;
}

.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div
  > div
  > div
  > div:hover {
  border-bottom: none;
}

/* days css */
.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div:nth-child(2)
  > span {
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ababab;
}

.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div:nth-child(2) {
  padding: 0 16px;
}

/* overall padding */
.CustomDateRangePicker > div > div {
  padding: 24px;
}

.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div:nth-child(3) {
  padding: 0;
}

.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div:nth-child(3)
  > div
  > div {
  padding: 2px;
}

/* dates available css */
.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div:last-child
  > div
  > div
  > button
  > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2a2828;
}

/* disabled dates css */
.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div:last-child
  > div
  > div
  > .Mui-disabled
  > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ababab;
}

/* rounded corners dates css */
.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div:nth-child(3)
  > div
  > div:last-child {
  border-radius: 0 50% 50% 0;
}

.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div:nth-child(3)
  > div
  > div:first-child {
  border-radius: 50% 0 0 50%;
}

/* month arrow css  */

.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div:first-child
  > div:first-child
  > button
  > svg {
  font-size: 24px;
  color: #000;
}

.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div:first-child
  > div:last-child
  > button
  > svg {
  font-size: 24px;
  color: #000;
}

.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > svg {
  display: none;
}
.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div {
  padding-right: 0 !important;
}

/* week trim css */
.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div:nth-child(2)
  > span:nth-of-type(odd) {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 9px;
}

.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div:nth-child(2)
  > span:nth-of-type(even) {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 13px;
}

.CustomDateRangePicker
  > div
  > div
  > div:last-child
  > div
  > div
  > div
  > div:nth-child(2)
  > span:nth-of-type(6) {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 9px;
}
.vjs-menu-item.vjs-captions-menu-item .vjs-menu-item-text,
.vjs-audio-button .vjs-menu-item-text {
  text-transform: capitalize;
}
.vjs-menu-item.vjs-captions-menu-item
  .vjs-menu-item-text
  .vjs-icon-placeholder {
  display: none !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.AudioVideoTranscription
  #fileInput {
    display: none;
  }
