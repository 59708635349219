/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 29, 2019 */

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url(./Montserrat-Medium.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./Poppins-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"), url(./Poppins-SemiBold.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SoraBold";
  src: local("SoraBold"), url(./Sora-Bold.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SoraSemiBold";
  src: local("SoraSemiBold"), url(./Sora-SemiBold.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Sora";
  src: local("Sora"), url(./Sora-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "MontserratSemiBold";
  src: local("MontserratSemiBold"),
    url(./Montserrat-SemiBold.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url(./OpenSans-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./Roboto-Regular.ttf) format("truetype");
  font-display: swap;
}
